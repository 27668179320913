import React, { useState } from 'react';
import { Box, Typography, Button, SwipeableDrawer } from '@mui/material';
import servicesData from './services.json';

const ServicePage = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  // Открыть выезжающее окно с выбранной услугой
  const handleServiceClick = (service) => {
    setSelectedService(service);
    setDrawerOpen(true);
  };

  // Закрыть выезжающее окно
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedService(null);
  };

  return (
    <Box>

    </Box>
  );
};

export default ServicePage;
