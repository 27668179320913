import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import './HomePage.css';
import home1 from '../../assets/home1.jpg';

const HomePage = () => {
  return (
    <Box>
      <Box component="section" sx={{ p: 2, mt: 1, pb: 1 }}>
        {/* Блок инфо */}
          <Box 
            src={home1} 
            component="img"
            alt="webapp" 
            sx={{
              width: '100%',
              borderRadius: '15px',
            }}
          />
      </Box>
      <Box component="section" sx={{ p: 2 }}>
        <Typography component="div" variant="h5" sx={{ fontSize: '1.2rem', color: '#6ab2f2', fontWeight: '600' }}>
          Что такое Telegram Web App?
        </Typography>
        <Typography
          variant="body1"
          sx={{
            pt: 2,
            fontSize: '1rem',
            lineHeight: '1.4',
            letterSpacing: '0.008em',
            color: '#ffffff'
          }}
        >
          Telegram — уже давно не просто мессенджер. Это универсальная платформа, на которой можно и почитать новости, и найти работу, а теперь еще и купить кроссовки. Все благодаря новому типу приложений внутри мессенджера — Telegram Web Apps (TWA).
        </Typography>
        <Typography
          variant="body1"
          sx={{
            pt: 2,
            fontSize: '1rem',
            lineHeight: '1.4',
            letterSpacing: '0.008em',
            color: '#ffffff'
          }}
        >
          TWA - это компактные веб-приложения, которые открываются внутри Telegram. Они основаны на функции ботов — автоматизированных чатов, которые помогают пользователям с самыми разными задачами. От оплаты подписок на онлайн-сервисы до заказа продуктов на дом.
        </Typography>
      </Box>
    </Box>
  );
};

export default HomePage;
